import styled from "styled-components";
import { screenWidths } from "../../util/theme";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PanelContainer = styled.div`
	display: flex;
	flex: 1;

	@media (max-width: ${screenWidths.l}) {
		flex-direction: column;
	}

	> * {
		flex: 1;
	}

	> :first-child {
		margin-right: 3rem;

		@media (max-width: ${screenWidths.l}) {
			margin-right: 0;
		}
	}
`;
