import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import useLang from "../../hooks/useLang";
import * as S from "../Time/Time.styles";

type Props = {
	children?: ReactNode;
};

const Time = ({ children }: Props) => {
	const [lang] = useLang();

	return (
		<S.Container>
			<S.Content>
				<FontAwesomeIcon icon={faClock} />
				<S.Text>
					{children} {lang.prices.minute}
				</S.Text>
			</S.Content>
		</S.Container>
	);
};

export default Time;
