import React from "react";
import useLang from "../../../hooks/useLang";
import { PriceInfo } from "../../../i18n/prices";
import Panel from "../../Panel/Panel";
import Time from "../../Time/Time";
import * as S from "./PriceTable.styles";

type Props = {
	priceInfo: PriceInfo[];
	title: string;
	subTitle?: string;
};

const PriceTable = ({ priceInfo, title, subTitle }: Props) => {
	const [, language] = useLang();

	return (
		<Panel title={title}>
			{subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
			<S.List>
				{priceInfo
					.filter((service) => !service.hidden)
					.map(
						(
							{
								name,
								nameEn,
								price,
								description,
								descriptionEn,
								timeMin,
								timeMax,
							},
							index
						) => (
							<S.Service key={index}>
								<S.Row>
									<S.TimeFormat>
										{language === "hu" ? name : nameEn || name}
										<Time>{timeMin ? `${timeMin}-${timeMax}` : timeMax}</Time>
									</S.TimeFormat>
									<S.Price>{price} Ft</S.Price>
									<S.MobileExtraInfo>
										<Time>{timeMin ? `${timeMin}-${timeMax}` : timeMax}</Time>
										<S.Price>{price} Ft</S.Price>
									</S.MobileExtraInfo>
								</S.Row>
								<S.Description>
									{language === "hu"
										? description
										: descriptionEn || description}
								</S.Description>
							</S.Service>
						)
					)}
			</S.List>
		</Panel>
	);
};

export default PriceTable;
