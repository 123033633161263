import styled from "styled-components";
import { screenWidths } from "../../util/theme";

export const Container = styled.div`
	display: flex;
	align-items: center;
	line-height: 1.375rem;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.75rem;
	color: #585858;
	background-color: gainsboro;
	border-radius: 0.5rem;
	padding: 0 0.5rem;
	margin: 0 0.25rem;

	@media (max-width: ${screenWidths.s}) {
		margin: 0;
	}
`;

export const Text = styled.div`
	margin-left: 0.25rem;
	white-space: nowrap;
`;
