import React from "react";
import * as S from "./Prices.styles";
import PriceData from "../../data/services.json";
import PriceTable from "./PriceTable/PriceTable";
import useLang from "../../hooks/useLang";

const Prices = () => {
	const [lang] = useLang();

	return (
		<S.Container>
			<S.PanelContainer>
				<PriceTable
					title={lang.prices.manicure}
					priceInfo={PriceData.Manicure}
				/>
				<PriceTable
					title={lang.prices.pedicure}
					priceInfo={PriceData.Pedicure}
				/>
			</S.PanelContainer>
			<S.PanelContainer>
				<PriceTable
					title={lang.prices.nails}
					priceInfo={PriceData.Nails}
					subTitle={lang.prices.nailsDesc}
				/>
				<PriceTable
					title={lang.prices.decorations}
					priceInfo={PriceData.Decoration}
				/>
			</S.PanelContainer>
		</S.Container>
	);
};

export default Prices;
