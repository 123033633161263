import styled from "styled-components";
import { screenWidths } from "../../../util/theme";

export const List = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	margin: 0 1.5rem;
	font-weight: bold;

	@media (max-width: ${screenWidths.s}) {
		margin: 0;
	}
`;

export const Price = styled.div`
	white-space: nowrap;
`;

export const MobileExtraInfo = styled.div`
	display: none;
	width: 100%;
	margin-top: 0.5rem;

	@media (max-width: ${screenWidths.s}) {
		display: flex;
		justify-content: space-between;

		${Price} {
			display: inline-block !important;
		}
	}
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5rem;

	@media (max-width: ${screenWidths.s}) {
		flex-direction: column;
		align-items: flex-start;

		${Price} {
			display: none;
		}
	}
`;

export const Description = styled.div`
	font-size: 0.9375rem;
	display: flex;
	text-align: justify;
	font-style: italic;
	margin-bottom: 1.5rem;
	font-weight: normal;
	text-decoration-line: none;
`;

export const Service = styled.div``;

export const TimeFormat = styled.div`
	display: flex;

	@media (max-width: ${screenWidths.s}) {
		> :last-child {
			display: none;
		}
	}

	> :last-child {
		margin-left: 0.5rem;

		@media (max-width: ${screenWidths.s}) {
			margin-left: 0;
		}
	}
`;

export const SubTitle = styled.div`
	display: flex;
	justify-content: center;
	font-style: italic;
	padding-bottom: 2rem;
`;
